const FooterData = [
    {
        title: "",
        submenu: [
            {

            }
        ]
    },
    {
        title: "",
        submenu: [
            {

            }
        ]
    },
    {
        title: "company",
        submenu: [
            {
                title: "Home",
                link: "/"
            },
            {
                title: "Contact us",
                link: "/contact"
            },
            {
                title: "Careers",
                link: "/careers"
            },
        ]
    },
    {
        title: "Sectors",
        submenu: [
            {
                title: "Government Contracts",
                link: "/government-contracts"
            },
        ]
    }
]

export default FooterData