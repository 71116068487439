const PiechartData1 = [
    {
        percentage: 75,
        title: 'WEB DEVELOPMENT',
    },
    {
        percentage: 80,
        title: 'SOCIAL MARKETING',
    },
    {
        percentage: 85,
        title: 'CONTENT CREATION',
    }
]

const PiechartData2 = [
    {
        percentage: 33,
        title: 'Resource Distribution: Media Projects',
        content: 'Lorem ipsum is simply dummy text of the printing and type.'
    },
    {
        percentage: 67,
        title: 'Resource Distribution: Government Contracts Focus',
        content: 'Lorem ipsum is simply dummy text of the printing and type.'
    }
]

export {PiechartData1, PiechartData2}