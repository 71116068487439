import React, { useState } from "react";

const Result = () => {
  return (
    <p className="success-message">Your Message has been successfully sent.</p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    const contactName = document.getElementById("contactName").value;
    const contactEmail = document.getElementById("contactEmail").value;
    const contactPhone = document.getElementById("contactPhone").value;
    const contactSubject = document.getElementById("contactSubject").value;
    const contactMessage = document.getElementById("contactMessage").value;

    postData("https://api.heroesraid.com/execute/send/message", {
      email: contactEmail,
      message: `${contactName} - ${contactSubject}\n${contactMessage}\n${contactPhone}`,
    }).then((success) => {
      console.log(success);
      showresult(success);
      e.target.reset();
      setSubmitDisabled(false);
    });
  };

  const sending = (<div><p>Sending your message, please wait...</p></div>);

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return submitDisabled ? sending : (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input
          id="contactName"
          type="text"
          name="fullname"
          placeholder="Your Name"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          id="contactEmail"
          type="email"
          name="email"
          placeholder="Your Email"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          id="contactPhone"
          type="text"
          name="phone"
          placeholder="Phone Number"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          id="contactSubject"
          type="text"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>

      <div className="rn-form-group">
        <textarea
          id="contactMessage"
          name="message"
          placeholder="Your Message"
          required
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"          
          disabled={submitDisabled}
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
