import React from 'react';

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Components
import Header, { HeaderNav, Menu, Topbar } from '../../Components/Header/Header';
import Blockquote from '../../Components/Blockquote/Blockquote';
import FooterMenu, { Footer } from '../../Components/Footers/Footer';
import SideButtons from "../../Components/SideButtons";

// Data
import FooterData from '../../Components/Footers/FooterData';
import Logo from '../../Assets/img/LionheartGlobalLogo.png';

const CareersPage = (props) => {
  return (
    <div style={props.style}>
      <SideButtons />
      {/* Header Start */}
      <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
        <Topbar className="bg-lightgray border-b home-business-topbar border-[#0000001a] sm:hidden md:px-0 header-with-topbar border-bottom px-[35px]">
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <Col className="col-12 text-center sm:text-start col-sm-auto me-auto ps-lg-0">
                {/*<SocialIcons theme="social-icon-style-01" size="xs" iconColor="dark" data={SocialIconsData} />*/}
              </Col>
              <Col className="col-auto none sm:block text-end lg:px-0">
                <span className="top-bar-contact-list border-l border-inherit py-[9px] px-[18px] text-[13px] inline-block float-left">
                  Lionheart Global Corporation
                </span>
              </Col>
            </Row>
          </Container>
        </Topbar>
        <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-[15px] sm:px-0" containerClass="md:px-0">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img className="default-logo" width="507" height="210" loading="lazy" src={Logo} alt="logo" />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:mx-[15px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-end p-0">
            <Menu {...props} />
          </Navbar.Collapse>
        </HeaderNav>
      </Header>
      {/* Header End */}
      {
        <>
          <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
            <Container>
              <div className="justify-center">
                <div className="md:mb-[60px] sm:mb-[40px] xs:mb-0">
                  <Row>
                    <Col className="blog-details-text last:mb-0 mb-24">
                      <h5 className="font-serif font-medium text-darkgray mb-[4.5rem]">Careers</h5>                    
                      <p className="mb-[25px]">Join Lionheart Global and dive into cool projects—games, SaaS, and government contracts. We’ll train you, mentor you, and help you grow. Ready to make an impact? We want fresh talent to help shape the future.</p>
                      
                      <Blockquote
                        className="my-[5.5rem] ml-24 sm:ml-0"
                        theme="blockquote-style-02"
                        title="Work hard, stay humble, and let your success do the talking."
                        author="Unknown" />
                      
                      <p className="my-[25px]">Get hands-on experience, competitive pay, and join a diverse, dynamic team. If you’re ready to innovate and grow, Lionheart Global is the place to be.</p>
                    </Col>                 
                  </Row>
                </div>
              </div>
            </Container>
          </section>

          {/* Footer Start */}
          <Footer className="bg-[#262b35] text-slateblue" theme="dark">
            <div className="py-[7%] lg:py-[8%] sm:py-[50px]">
              <Container>
                <Row className="justify-between md:justify-center sm:justify-between">
                  <Col className="m-0 md:text-center sm:text-start" lg={{ offSet: 0, span: 3, order: 0 }} sm={{ span: 6, order: 5, offSet: 2 }} md={{ span: 4, offset: 0, order: 5 }} xs={{ span: 12, order: 5, offSet: 2 }}>
                    <p className="mb-[25px] text-slateblue sm:w-[90%] md:mb-[15px] md:text-center sm:text-start"><strong>ABN:</strong> 13 659 392 971</p>
                    <p className="mb-[25px] text-slateblue sm:w-[90%] md:mb-[15px] md:text-center sm:text-start"><strong>ACN:</strong> 659 392 971</p>
                    <p>© Copyright {new Date().getFullYear()} <Link to="/" className="underline underline-offset-4 text-white font-normal">Lionheart Global Corporation Pty Ltd</Link></p>
                  </Col>
                  <FooterMenu data={FooterData} lg={{ span: 2, offSet: 1, order: 0 }} md={{ span: 3, order: 0 }} sm={{ span: 4, offSet: 1, order: 2 }} className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]" titleClass="capitalize" />
                </Row>
              </Container>
            </div>
          </Footer>
          {/* Footer End */}
        </>
      }
    </div>
  );
};

export default CareersPage;
