const HeaderData = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: "About",
        megamenu: [
            {
                title: 'Current Projects',
                dropdown: [
                    {
                        title: 'Government Contracts',
                        link: '/government-contracts'
                    },
                ],
            },
            {
                title: 'More ',
                dropdown: [
                    {
                        title: 'Contact Us',
                        link: '/contact'
                    },
                    {
                        title: 'Careers',
                        link: '/careers'
                    }
                ]
            }
        ],
    }
]

export default HeaderData